<template>
  <div>
    <!--begin:: App Smart List-->
    <AppSmartList
      ref="appSmartList"
      :breadcrumbData="breadcrumbData"
      :title="$t('label.expiredUserPackage')"
      :accessRights="accessRights"
      :actions="actions"
      :tableHeaders="headers"
    ></AppSmartList>
    <!--end:: AppSmartList-->
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import commonMixin from '@/core/mixins/common.mixin';
  import { i18nHelper } from '@/core/utils';
  import { MODULE, ACTION, APP_COMPONENT_NAME } from '@/core/constants';
  import {
    PACKAGE_GET_PRODUCTION_PACKAGE_MEMBERS_LIST,
    PACKAGE_INITIAL_PRODUCTION_PACKAGE_MEMBERS_LIST_STATE
  } from '@/core/store/package.module';
  // import { listService } from '@/core/services';
  export default {
    name: 'PackageList',
    components: {
      AppSmartList
    },
    mixins: [commonMixin],
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          { title: i18nHelper.getMessage('label.expiredUserPackage') }
        ]
      },
      accessRights: {
        moduleName: MODULE.PACKAGE,
        actions: {
          create: ACTION.APP_INFO_CREATE,
          read: ACTION.APP_INFO_READ,
          update: ACTION.APP_INFO_UPDATE,
          delete: ACTION.APP_INFO_DELETE
        }
      },
      // filters: [
      //   {
      //     label: 'status',
      //     key: 'status',
      //     value: '',
      //     options: listService.getMemberStatusList(),
      //     type: SMART_FORM_FIELD_TYPE.SELECT
      //   },
      //   {
      //     label: 'memberName',
      //     key: 'memberName',
      //     value: ''
      //   },
      //   {
      //     label: 'ownerName',
      //     key: 'ownerName',
      //     value: ''
      //   }
      // ],
      actions: {
        viewList: {
          module: 'userPackage',
          state: 'productionPackageMembersList',
          action: PACKAGE_GET_PRODUCTION_PACKAGE_MEMBERS_LIST,
          initialStateAction:
            PACKAGE_INITIAL_PRODUCTION_PACKAGE_MEMBERS_LIST_STATE,
          title: i18nHelper.getMessage('label.getProductionPackageList')
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'fname',
          name: 'name',
          active: true,
          sortable: true,
          class: 'min-w-150px'
        },
        {
          key: 'status',
          name: 'status',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_ENUM_LABEL
        },
        {
          key: 'role',
          name: 'memberType',
          active: true,
          sortable: true
        },
        {
          key: 'contactNumber',
          name: 'contactNumber',
          active: true,
          sortable: true
        },
        {
          key: 'email',
          name: 'email',
          active: true,
          sortable: true
        },
        {
          key: 'createdAt',
          name: 'registerDateTime',
          active: true,
          sortable: true
        },
        {
          key: 'lastLogin',
          name: 'lastLoginDateTime',
          active: true,
          sortable: true
        }
      ]
    })
  };
</script>

<style></style>
